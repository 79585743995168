import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
// @ts-ignore
import html2pdf from 'html2pdf.js';

@Options({
    name: 'receipt-pdf-component',
    props: {
        template: {
            type: String,
        },
        receiptNumber: {
            type: String,
        }
    }
})

export default class ReceiptPdfComponent extends Vue {
    public template: string = '';
    public receiptNumber: string = '';
    public pdfOptions = {
        margin: 15,
        image: {
            type: 'png',
            quality: 1,
        },
        html2canvas: {scale: 3},
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'p',
        },
    };

    public savePdf() {
        if (this.template) {
            html2pdf().from(this.template).set(this.pdfOptions).save(`${this.receiptNumber}`);
        }
    }
}