<style src="./receipt-info.component.scss" lang="scss"></style>

<template>
  <div class="receipt-info">
    <div class="row">
      <div class="col-12">
        <v-divider class="my-40"/>
      </div>
      <div class="col-12">
        <receipt-data-component :receipt="receipt"/>
      </div>
      <div class="col-12">
        <receipt-pdf-component :template="receipt.templateForPDF" :receiptNumber="receipt.receiptNumber"/>
        <v-divider class="my-40"/>
      </div>
      <div class="col-12">
        <receipt-form-component @sendReceipt=" (i) => $emit('sendReceipt', i)"/>
      </div>
    </div>
  </div>

  <iframe v-show="false" class="pdf">
  </iframe>
</template>

<script src="./receipt-info.component.ts"></script>
