export class ApplicationModel {
    public pin: string | null = null;
    public simCount: string | null = null;
    public imeiNumbers: string[] = [];
    public phone: number | null = null;
    public documentNumber: number | null | string = null;
    public documentType: number | null | string = null;

    constructor(userForm: any, imeiForm: any, user: any) {
        this._setPin(userForm);
        this._setDocumentNumber(userForm, user);
        this._setDocumentType(userForm, user);
        this._setSimCount(imeiForm);
        this._setImeiNumbers(imeiForm);
        this._setPhone(userForm);
    }

    /**
     * Set Pin
     * @param fin
     * @private
     */

    private _setPin({fin}: any): void {
        this.pin = fin;
    }

    /**
     * Set simCount
     * @param simCount
     * @private
     */

    private _setSimCount({simCount}: any): void {
        this.simCount = simCount;
    }

    /**
     * Set ImeiNumbers
     * @param imei
     * @private
     */

    private _setImeiNumbers({imei}: any): void {
        if (imei.first) {
            this.imeiNumbers.push(imei.first);
        }
        if (imei.second) {
            this.imeiNumbers.push(imei.second);
        }
    }

    /**
     * Set phone
     * @param phone
     * @private
     */

    private _setPhone({phone}: any): void {
        this.phone = phone;
    }

    /**
     * Set DocumentNumber
     * @param userForm
     * @private
     */

    private _setDocumentNumber(userForm: any, user: any): void {
        if (!user) {
            this.documentNumber = userForm.documentNumber;
        } else {
            this.documentNumber = null;
        }
    }

    private _setDocumentType(userForm: any, user: any) {
        if (!user) {
            this.documentType = userForm.documentTypeNumber;
        } else {
            this.documentType = null;
        }
    }
}