import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class AllServicesSandbox {
    private store = store;

    constructor() {
    }

    getAllServices(payload: { pageNumber: number, pageSize: number }): void {
        this.store.dispatch('getAllServices', payload);
    }

}
