import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {store} from '@/store';
import {IServices} from '@/pages/allservices/types/allservices';
import {CategoriesSandbox} from './sandbox/categories.sandbox';
import {ICategory} from '@/pages/categories/types/category';
import CategoriesCardComponent from '@/pages/categories/components/categories-card/categories-card.component.vue';
import CategoriesHeaderComponent from '@/pages/categories/components/categories-header/categories-header.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import ServiceCardComponent from '@/core/shared/service-card/service-card.component.vue';

@Options({
    name: 'categories-component',
    components: {
        CategoriesCardComponent,
        CategoriesHeaderComponent,
        NotDataComponent,
        ServiceCardComponent
    }
})
export default class CategoriesComponent extends Vue {
    locale$: any = locale;
    locale: any = null;
    private sandbox: CategoriesSandbox = container.resolve(CategoriesSandbox);

    get services(): IServices[] {
        return store.getters._getAllServices;
    }
    get category(): ICategory {
        return store.getters._getCategories;
    }
    public created(): void {
        this.locale$.subscribe((res: any) =>   {
            if (res && res.allservices) {
                this.locale = res.allservices
            }
        });
        let id = this.$route.params.id.toString();
        this.sandbox.getCategories(id);
    }

}
