import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import BoxComponent from "@/core/shared/box/box.component.vue";
import choiceRouter from '@/core/helpers/routers'
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {container} from 'tsyringe';

@Options({
    name: 'categories-card-component',
    components:{BoxComponent},
    props: {
        service: {
            type: Object,
            required: true,
        }, // title, webSite, tel, address, description, map
    },
})

export default class CategoriesCardComponent extends Vue {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public locale$: any = locale;
    public locale: any = null;
    public created():void {
        this.locale$.subscribe((res: any) => {
            if (res && res.allServices) {
                this.locale = res.allServices.allservicesCard
            }
        });
    }
    public defineService(id: number): void {
        // return choiceRouter(id);
    }

    public clickLink(service: any) {
        // if (service.authRequired && !localStorage.getItem('mainToken')) {
        //     this.sandboxAuth.getSSOToken(choiceRouter(service.id));
        // } else {
        //     this.$router.push({name: choiceRouter(service.id)});
        // }
    }
}
