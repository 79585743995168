<style src="./user-apply.component.scss" lang="scss"></style>

<template>
  <div class="row">
    <div class="col-12">
      <v-card class="d-lg-block d-none">
        <div class="row">
          <div class="col-md-6">
            <h3>
              {{ locale.myAppeals }}
            </h3>
          </div>
          <div class="col-md-6">
            <SearchComponent @searchWord="sendSearch"/>
          </div>
        </div>
        <div class="col-12">
          <v-table class="mt-20 mb-20 user-apply__table" id="tableUserApply" v-if="totalCountServiceRequest>0">
            <template #header>
              <tr>
                <th>Qurum adı</th>
                <th>Xidmət adı</th>
                <th>Tarix</th>
              </tr>
            </template>
            <template #body>

              <tr v-for="item in serviceRequest" class="active">
                <td>{{ item.structureName }}</td>
                <td>{{ item.serviceName }}</td>
                <td>{{ item.date }}
                  <!--              <v-btn class="btn" :class="[{'btn&#45;&#45;green':item.status}, {'btn&#45;&#45;yellow':!item.status}]">-->
                  <!--                <template v-if="item.status">-->
                  <!--                  Həll edildi-->
                  <!--                </template>-->
                  <!--                <template v-else>-->
                  <!--                  Gözləmədə-->
                  <!--                </template>-->
                  <!--              </v-btn>-->
                </td>
              </tr>
            </template>
          </v-table>
          <v-alert color="warning" v-else>
            {{ locale.noData }}
          </v-alert>
        </div>
        <div class="col-12 text-center">
          <v-pagination
              ref="pagination"
              :limit="pageSize"
              :dataCount="totalCountServiceRequest"
              :page="pageNumber"
              @changePage="changePage"
              class="mt-40 mb-100  "/>
        </div>
      </v-card>
      <div class="mobile d-lg-none d-block">
        <h3>
          {{ locale.myAppeals }}
        </h3>
        <SearchComponent @searchWord="sendSearch"/>
        <apply-mobile-component  v-if="totalCountServiceRequest>0" :data="serviceRequest"/>
        <v-alert color="warning" v-else>
          {{ locale.noData }}
        </v-alert>
        <div class="text-center">
          <v-pagination
              ref="pagination"
              :limit="pageSize"
              :dataCount="totalCountServiceRequest"
              :page="pageNumber"
              @changePage="changePage"
              class="mt-40 mb-100  "/>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./user-apply.component.ts"></script>
