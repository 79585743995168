import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {Validation} from '@/core/mixin/validation';
// @ts-ignore
import jsPDF from 'jspdf';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import {IReceiptPayment} from '@/pages/services/types/receipt-mcqs';
import ReceiptDataComponent from '@/pages/services/components/receipt-data/receipt-data.component.vue';
import ReceiptPdfComponent from '@/pages/services/components/receipt-pdf/receipt-pdf.component.vue';
import ReceiptFormComponent from '@/pages/services/components/receipt-form/receipt-form.component.vue';

@Options({
    name: 'receipts-info-component',
    props: {
        receipt: {
            type: Object
        }
    },
    components:{
        ReceiptDataComponent, ReceiptPdfComponent, ReceiptFormComponent
    },
    emits:['sendReceipt']
})

export default class ReceiptInfoComponent extends mixins(Validation) {
    public receipt: IReceiptPayment = {
        date: null,
        receiptNumber: null,
        amount: null,
        account: null,
        // templateForMessage: null,
        templateForPDF: null,
    };
    public email: string = '';
    public errorEmail: boolean = false;
    public pdfOptions = {
        margin: 15,
        image: {
            type: 'png',
            quality: 1,
        },
        html2canvas: {scale: 3},
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'p',
        },
    };
    public sendReceipt() {
        if (this.validationEmail(this.email)) {
            this.errorEmail = false;
            this.$emit('sendReceipt', {
                email: this.email
            });
        } else {
            this.errorEmail = true;
        }
    }

    public savePdf() {
        if (this.receipt.templateForPDF) {
            html2pdf().from(this.receipt.templateForPDF).set(this.pdfOptions).save(`${this.receipt.receiptNumber}`);
        }
    }

}
