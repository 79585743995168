import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'receipt-data-component',
    props: {
        receipt: {
            type: Object,
            required: true
        }
    }
})

export default class ReceiptDataComponent extends Vue {

}