<style src="./receipt-data.component.scss" lang="scss"> </style>

<template>
  <div class="receipt-data">
    <div class="row">
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Ödənişin mənbəyi</div>
        <div class="receipt-data__description">Smartpay.az</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Tarix</div>
        <div class="receipt-data__description">{{ receipt?.date }}</div>
      </div>
      <div class="col-lg-6 col-12 mb-20" v-for="(item, index) in receipt.imeis">
        <div class="receipt-data__title">IMEI{{index+1}}</div>
        <div class="receipt-data__description">{{ item }}</div>
      </div>

      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">VÖEN</div>
        <div class="receipt-data__description">9900048721</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Alan</div>
        <div class="receipt-data__description">MCQS</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Ünvan</div>
        <div class="receipt-data__description">74 Əlibəy Hüseynzadə</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Hesab</div>
        <div class="receipt-data__description">{{ receipt?.account }}</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Qəbz nömrəsi</div>
        <div class="receipt-data__description">{{ receipt?.receiptNumber }}</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Məbləğ</div>
        <div class="receipt-data__description">{{ receipt?.amount }}AZN</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">Ödəniş sisteminin əlaqə nömrəsi</div>
        <div class="receipt-data__description">157</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title">İş saatları</div>
        <div class="receipt-data__description">B.er - Şənbə 9.00-dan 19.00-dək</div>
      </div>
      <div class="col-lg-6 col-12 mb-20">
        <div class="receipt-data__title"></div>
        <div class="receipt-data__description"></div>
      </div>
    </div>
  </div>
</template>

<script src="./receipt-data.component.ts"></script>