<style src="./receipt-form.component.scss" lang="scss"> </style>

<template>
  <div class="receipt-form">
    <div class="row">
      <div class="col-12 mb-30">
        Qəbzi e-mailə göndər
      </div>
      <div class="col-12">
        <form class="form" @submit.prevent="sendReceipt">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div class="form__group">
                <div class="row">
                  <div class="col-12">
                    <label for="" class="v-label">E-mail</label>
                  </div>
                  <div class="col-12 col-lg-10">
                    <input
                        :class="{'v-input--error':errorEmail}"
                        v-model="email" id="email" name="email" placeholder="E-mailinizi daxil edin" type="text"
                        class="v-input">
                  </div>
                  <div class="col-12 col-lg-2 text-center">
                    <v-btn color="dark">Göndər</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script src="./receipt-form.component.ts"></script>