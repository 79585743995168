<style src="./services.component.scss" lang="scss"></style>

<template>
  <div class="services">
    <section class="mt-20">
      <div class="container-fluid v-container">
        <div class="row">
          <div class="col-12">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </div>
        </div>
        <div class="row mb-7 mt-7">
          <div class="col-md-12">
            <customer-info-component :user="userData"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-card>
              <steps-card-component
                  @selectDocumentGroup="selectedDocumentGroup"
                  :documentGroups="documentGroups"
                  :documentTypes="documentTypes"
                  :price="responsiveImeiCheck.price"
                  :userData="userData"
                  :current="current"
                  :step="step"
                  :stepDescription="stepDescription"
                  :imeiInfo="imeiInfo"
                  :formRegistrationData="formRegistrationData"
                  :formError="formError"
                  :error="error"/>
              <v-divider class="services__divider"/>
                            <div class="btn__wrapper d-flex justify-center mt-40">
                              <v-btn v-if="current !== 1" color="white mr-12" @click="decrementCurrent">{{ locale.prevStep }}</v-btn>
                              <v-btn v-if="current !== step" @click="incrementCurrent" color="dark">{{ locale.nextStep }}</v-btn>
                              <v-btn v-else color="dark" @click="goToPayment">{{ locale.complete }}</v-btn>
                            </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>

  <v-modal
      title="Məlumat"
      ref="modal"
  >
    <template v-slot:body>
      <img class="service__img" v-if="responsiveImeiCheck.statusCode===428"
           src="@/assets/images/icons/modal-accept.svg" alt="">
      <img class="service__img" v-if="
      responsiveImeiCheck.statusCode!==200 &&  responsiveImeiCheck.statusCode!==428"
           src="@/assets/images/icons/modal-err.svg" alt="">
      <img class="service__img" v-if="responsiveImeiCheck.statusCode===200"
           src="@/assets/images/icons/modal-info.svg" alt="">
      <div
          v-if="responsiveImeiCheck.statusCode===428"
          class="steps-card__modal-title">
        Qeydiyyatlı IMEI
      </div>
      <div
          v-if="responsiveImeiCheck.statusCode===200"
          class="steps-card__modal-title">
        Uyğun IMEI
      </div>
      <div class="steps-card__modal-description">
        {{ responsiveImeiCheck.description || responsiveImeiCheck.statusText }}
      </div>
      <v-card v-if="responsiveImeiCheck.price"
              class="modal-price"
              :dark="true">
        Dövlət rüsumu: <span> {{ responsiveImeiCheck.price }} AZN</span>
      </v-card>
    </template>
    <template v-slot:footer>
      <div class="modal-btn-group">
        <template v-if="userData.name.length && responsiveImeiCheck.statusCode === 200">
          <v-btn class="modal-btn"
                 color="dark"
                 @click="clickNextCurrentModal">Növbəti
          </v-btn>
        </template>
        <div v-if="!userData.name.length && responsiveImeiCheck.statusCode === 200">
          <div>
            <v-btn color="dark"
                   @click="isNotRegistration"
                   class="modal-btn py-12">Qeydiyyatsız davam et
            </v-btn>
          </div>
          <div>
            <v-btn
                color="black"
                @click="login"
                class="modal-btn py-12 mt-12">Daxil ol
            </v-btn>
          </div>
        </div>
        <template v-if="responsiveImeiCheck.statusCode != 200">
          <v-btn class="py-10 px-80 modal-btn"
                 color="black"
                 @click="closeModal">Bağla
          </v-btn>
        </template>
      </div>
    </template>
  </v-modal>
  <v-modal
      title="Məlumat"
      ref="modalApplication"
  >
    <template v-slot:body>
      {{ applicationStatus.statusText || applicationStatus.description }}
    </template>
  </v-modal>
  <v-modal
      :footer="!payment"
      :title="payment?'Qəbz':'Məlumat'"
      ref="modalPayment"
  >
    <template v-slot:body>
      <div class="mb-30 modal-img">
        <!--        <div class="text-center" v-if="payment"><img src="@/assets/images/icons/modal-accept.svg" alt=""></div>-->
        <div class="text-center icon" v-if="payment"><img src="@/assets/images/icons/modal-info.svg" alt=""></div>
        <div class="text-center icon" v-if="!payment"><img src="@/assets/images/icons/modal-err.svg" alt=""></div>
        <div class="steps-card__modal-title text-center mb-12" v-if="payment">Sizin ödəniş uğurludur</div>
        <div class="steps-card__modal-description text-center mb-12 mb-50" v-if="payment">Qəbz aşağıda təqdim olunub
        </div>
        <div class="steps-card__modal-title" v-else>Qeydiyyat uğursuz oldu</div>
        <receipt-info-component v-if="payment" @sendReceipt="sendReceipt" :receipt="receipt"/>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-btn-group">
        <v-btn class="py-10 px-80 modal-btn"
               color="black"
               @click="paymentClickModal">{{ locale.close }}
        </v-btn>
      </div>
    </template>
  </v-modal>
    <v-modal
        class="imei-info-modal"
        title="Məlumat"
        ref="modalInfo"
        size="lg"
    >
      <template v-slot:body>

        <div class="text-center icon"><img src="@/assets/images/icons/imei-info.svg" alt=""></div>
  <!--      <div-->
  <!--          class="steps-card__modal-title">-->
  <!--      </div>-->
        <div class="steps-card__modal-description text-center" v-html="localeService.imeiInfo"/>
      </template>
      <template v-slot:footer>
        <div class="modal-btn-group">
          <v-btn class="py-10 px-80 modal-btn"
                 color="black"
                 @click="closeImeiInfoModal()">{{ locale.close }}
          </v-btn>
        </div>
      </template>
    </v-modal>
<!--  <v-modal-->
<!--      :closeOnOutside="false"-->
<!--      class="imei-info-modal"-->
<!--      title="Məlumat"-->
<!--      ref="modalError"-->
<!--      size="lg"-->
<!--  >-->
<!--    <template v-slot:body>-->
<!--      <div class="text-center icon mt-30"><img src="@/assets/images/icons/modal-err.svg" alt=""></div>-->
<!--      <div class="steps-card__modal-description text-center mt-20" v-html="localeService.imeiErrorInfo"/>-->
<!--    </template>-->
<!--    <template v-slot:footer>-->
<!--      <div class="modal-btn-group">-->
<!--                <v-btn class="py-10 px-80 modal-btn"-->
<!--                       color="black"-->
<!--                       @click="closeImeiErrorModal">{{ localeService.prev }}-->
<!--                </v-btn>-->
<!--      </div>-->
<!--    </template>-->
<!--  </v-modal>-->
</template>

<script src="./services.component.ts"></script>
