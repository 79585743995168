<style src="./categories-header.component.scss" lang="scss"></style>

<template>
  <div class="organisation-header">
    <div class="organisation-header__title d-flex align-center">
      <img :src="icon" alt="icon">
      <div class="organisation-header__text ml-6">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script src="./categories-header.component.ts"></script>
