export class PaymentImeiStatusModel {
    public response: number | null = null;
    public imeiInfo: number | null = null;
    public current: number = 4;
    public userData: object = {};
    public url: string | null = null;

    constructor(imei: any, response: any, userData: any, url: any) {
        this._setResponse(response);
        this._setImeiInfo(imei);
        this._setUserData(userData);
        this._setUrl(url);
    }

    private _setResponse(response: any): void {
        this.response = response;
    }

    private _setImeiInfo(imei: any): void {
        this.imeiInfo = imei;
    }

    private _setUserData(userData: any): void {
        this.userData = userData;
    }

    private _setUrl(url: any) {
        this.url = url;
    }
}