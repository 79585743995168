import 'reflect-metadata';
import {store} from '@/store';
import {Options, Vue} from 'vue-class-component';
import TableComponent from '@/packages/VTable/table.component.vue';
import SearchComponent from '@/pages/user-account/components/user-search/search.component.vue';
import PaginationComponent from '@/packages/VPagination/pagination.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {UserApplySandbox} from '@/pages/user-apply/sandbox/user-apply.sandbox';
import {IServiceRequest} from '@/pages/user-apply/types/service-request';
import ApplyMobileComponent from '@/core/shared/apply-mobile/apply-mobile.component.vue';

@Options({
    name: 'user-apply-component',
    components: {
        TableComponent,
        SearchComponent,
        PaginationComponent,
        ApplyMobileComponent
    }
})

export default class UserApplyComponent extends Vue {
    private sandbox: UserApplySandbox = container.resolve(UserApplySandbox);
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public word: string = '';

    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    get serviceRequest(): IServiceRequest[] {
        return this.store.getters._getServiceRequests;
    }

    get totalCountServiceRequest(): number {
        return this.store.getters._getTotalCountServiceRequests;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
            }
        });
    }

    public mounted() {
        this.sandbox.getServiceRequests({
            token: localStorage.getItem('mainToken'),
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            word: this.word,
        });
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        this.sandbox.getServiceRequests({
            token: localStorage.getItem('mainToken'),
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
            word: this.word
        });
        window.scroll(0, 0);

        // window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

    public sendSearch(info: string) {
        this.word = info;
        this.resetPagination();
        this.sandbox.getServiceRequests({
            token: localStorage.getItem('mainToken'),
            pageSize: 10,
            pageNumber: 1,
            word: this.word
        });
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination?.reset();
            this.pageNumber = 1;
        }
    }


}
