import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "organisations" }
const _hoisted_2 = { class: "container-fluid v-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "mt-20 mb-80" }
const _hoisted_6 = { class: "container-fluid v-container" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_all_services_header_component = _resolveComponent("all-services-header-component")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_service_card_component = _resolveComponent("service-card-component")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_card, { class: "mt-48" }, {
              default: _withCtx(() => [
                _createVNode(_component_all_services_header_component, {
                  title: _ctx.locale.header.headerPage,
                  icon: "services.svg"
                }, null, 8, ["title"])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "col-lg-4 col-md-6 col-12 mb-30"
            }, [
              _createVNode(_component_service_card_component, { item: item }, null, 8, ["item"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_v_pagination, {
              ref: "paginationServices",
              limit: _ctx.pageSize,
              dataCount: _ctx.totalCountServices,
              page: _ctx.pageNumber,
              onChangePage: _ctx.changePage,
              class: "mt-40 mb-100"
            }, null, 8, ["limit", "dataCount", "page", "onChangePage"])
          ])
        ])
      ]),
      _createVNode(_component_router_view, {
        key: _ctx.$route.path
      })
    ])
  ]))
}