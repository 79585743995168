<style src="./receipt-pdf.component.scss" lang="scss"> </style>

<template>
  <div class="receipt-pdf">
    <v-card>
      <div @click.prevent="savePdf" class="d-lg-flex d-block justify-between">
        <span class="d-block">*Qəbz ödəniş balansa oturana qədər saxlanılmalıdır</span>
        <span class="d-block" :style="{'color':'#14142B'}">
              Qəbzi yüklə
              <img class="ml-20" src="@/assets/images/icons/download-file.svg" alt=""></span>
      </div>
    </v-card>
  </div>
</template>

<script src="./receipt-pdf.component.ts"></script>