import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import StepsCardComponent from '@/pages/services/components/steps-card/steps-card.component.vue';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {ServicesSandbox} from '@/pages/services/sandbox/services.sandbox';
import {container} from 'tsyringe';
import {PostImeiStatusModel} from '@/pages/services/models/post-imei-status.model';
import {ApplicationModel} from '@/pages/services/models/application.model';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {UrlImeiStatusModel} from '@/pages/services/models/url-imei-status.model';
import {PaymentImeiStatusModel} from '@/pages/services/models/payment-imei-status.model';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {Router} from '@/core/mixin/router';
import ReceiptInfoComponent from '@/pages/services/components/receipt/receipt-info.component.vue';

@Options({
    name: 'services-component',
    components: {
        StepsCardComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
        ReceiptInfoComponent
    }
})

export default class ServicesComponent extends mixins(Router) {
    public service = 6;
    public sandbox: ServicesSandbox = container.resolve(ServicesSandbox);
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public store = store;
    public url: string = '';
    public current: number = 1;
    public step: number = 4;
    public locale$: any = locale;
    public locale: any = null;
    public localeService: any = null;
    public checkRegistration = false;
    public payment: boolean | null = null;
    public orderId: string | null = null;
    public applicationStatus = {
        statusCode: '',
        statusText: '',
        description: '',
    };
    public error = {
        imei: {
            first: false,
            second: false,
        },
        checkImei: true,
    };

    public formError = {
        fin: false,
        document: false,
        tel: false
    };

    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
        modalPayment: {
            openModal: () => void;
            closeModal: () => void
        },
        modalApplication: {
            openModal: () => void;
            closeModal: () => void
        },
        modalInfo: {
            openModal: () => void;
            closeModal: () => void
        },
        modalError: {
            openModal: () => void;
            closeModal: () => void
        }
    };

    get receipt() {
        return this.store.getters._getReceiptPayment;
    }


    get isToken() {
        if (localStorage.getItem('mainToken')) {
            this.checkRegistration = true;
        }
        return localStorage.getItem('mainToken');
    }

    get formRegistrationData() {
        return this.store.getters._getFormRegistrationData;
    }

    get userData() {
        this.store.commit('SET_FORM_IMEI', store.getters._getUserFullData);
        return store.getters._getUserFullData;
    }

    get documentTypes() {
        return this.store.getters._getDocumentTypes;
    }

    get documentGroups() {
        return this.store.getters._getDocumentGroups;
    }

    get imeiInfo() {
        return this.store.getters._getImeiInfo;
    }

    get responsiveImeiCheck() {
        return this.store.getters._getResponsiveImeiCheck;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }


    get stepDescription() {
        return [
            {
                number: 1,
                description: this.localeService.step1,
            },
            {
                number: 2,
                description: this.localeService.step2,
            },
            {
                number: 3,
                description: this.localeService.step3,
            },
            {
                number: 4,
                description: this.localeService.step4,
            },
        ];
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
                this.localeService = res.services.specialService;
            }
        });
        // this.sandbox.getCommonService(this.service);
        // this.sandbox.getCommonServiceHeader(this.service);
    }

    public openModalInfo(): void {
        if (this.current === 1 && this.payment === null) {
            setTimeout(function (scope: any) {
                scope.$refs.modalInfo.openModal();
                // scope.$refs.modalError.openModal();
            }, 430, this);
        }
    }

    public mounted(): void {
        // this.$refs.modalError.openModal();
        this.checkRouteQuery();
        this.checkPaymentInformation();
        localStorage.removeItem('mcqs_payment_store');
        this.getServiceData();
        this.sandbox.getDocumentGroups().then((res: any) => {
            this.sandbox.getDocumentTypes(this.documentGroups[0].id);
        });
        this.openModalInfo();
        this.getServiceData();
    }


    public unmounted(): void {
        this.store.commit('CLEAR_IMEI_DATA');
        localStorage.removeItem('mcqs_store');
        localStorage.removeItem('mcqs_payment_store');
    }


    // login

    public login(): void {
        let information = JSON.stringify(new UrlImeiStatusModel(this.imeiInfo, this.responsiveImeiCheck));
        let result = btoa(unescape(encodeURIComponent((information))));
        localStorage.setItem('mcqs_store', result);
        this.sandboxAuth.getSSOToken();
    }

    // modal

    public modalCheck(): void {
        this.$refs.modal.openModal();
    }

    public closeModal() {
        this.$refs.modal.closeModal();
    }

    // action modal

    public isNotRegistration() {
        this.checkRegistration = false;
        this.closeModal();
        this.nextCurrent();
    }

    public clickNextCurrentModal() {
        this.error.checkImei = false;
        this.nextCurrent();
        this.closeModal();
    }

    public checkRouteQuery() {
        let string = localStorage.getItem('mcqs_store');
        let result = {
            imeiInfo: '',
            response: '',
            current: 0,
            url: ''
        };

        if (string && string.length) {
            result = JSON.parse(atob(unescape(decodeURIComponent(string))));
        }
        if (result) {
            if (result.imeiInfo) {
                this.store.commit('SET_IMEI_INFO', result.imeiInfo);
            }
            if (result.response) {
                this.store.commit('SET_RESPONSIVE_IMEI_CHECK', result.response);
            }
            if (result.current) {
                this.current = result.current;
            }
            if (result.url) {
                this.url = result.url;
            }
        }
        if (this.$route.query?.payment) {
            this.payment = JSON.parse(atob(unescape(decodeURIComponent(String(this.$route.query?.payment)))));
            if (this.payment) {
                this.orderId = String(this.$route.query?.order_id);
                if (this.orderId) {
                    this.sandbox.getReceiptPayment({
                        transactionId: this.$route.query?.order_id,
                    });
                }
            }
            this.$refs.modalPayment.openModal();
        } else {
            localStorage.removeItem('mcqs_store');
        }
    }

    public checkPaymentInformation() {
        let string = localStorage.getItem('mcqs_payment_store');
        if (string) {
            let result = JSON.parse(atob(unescape(decodeURIComponent(string))));
            if (result) {
                if (result.imeiInfo) {
                    this.store.commit('SET_IMEI_INFO', result.imeiInfo);
                }
                if (result.response) {
                    this.store.commit('SET_RESPONSIVE_IMEI_CHECK', result.response);
                }
                if (result.userData) {
                    this.store.commit('SET_FORM_REGISTRATION_DATA', result.userData);
                }
                if (result.current) {
                    this.current = result.current;
                }
            }
        }
    }

    public selectedDocumentGroup(info: any) {
        this.sandbox.getDocumentTypes(info);
    }

    // change current
    public incrementCurrent(): void {
        this.changeCurrent();
        if (!this.error.imei.first &&
            !this.error.imei.second) {
            if (this.current == 1) {
                this.nextCurrent();
                return;
            }
            if (this.current === 2 && !this.error.checkImei) {
                this.nextCurrent();
                return;
            }
            if (this.current === 3 && !this.formError.fin && !this.formError.document && !this.formError.tel) {
                const data = new ApplicationModel(this.formRegistrationData, this.imeiInfo, this.userData.name.length);
                this.sandbox.postAddApplication({
                    form: data,
                    request: this.responsiveImeiCheck.request,
                }).then((res: any) => {
                    let information = JSON.stringify(
                        new PaymentImeiStatusModel(this.imeiInfo, this.responsiveImeiCheck, this.formRegistrationData, res.merchantPayment));
                    let result = btoa(unescape(encodeURIComponent((information))));
                    localStorage.setItem('mcqs_payment_store', result);
                    this.applicationStatus = res;
                    if (res.statusCode === 200) {
                        this.nextCurrent();
                        this.url = res.merchantPayment;
                    } else {
                        this.$refs.modalApplication.openModal();
                    }

                });
                return;
            }
        }
    }

    public decrementCurrent(): void {
        if (this.current === 3) {
            this.error.checkImei = true;
        }
        if (this.current >= 2) {
            this.error.imei.first = false;
            this.error.imei.second = false;
            --this.current;
        }
    }

    public nextCurrent() {
        if (this.current < this.step) {
            ++this.current;
        }
    }

    public changeCurrent(): void {
        if (this.current === 2) {
            this.checkImei();
            if (!this.error.imei.first && !this.error.imei.second) {
                this.sandbox.getCheckImei(new PostImeiStatusModel(this.imeiInfo)).then((res: any) => {
                    this.store.commit('SET_RESPONSIVE_IMEI_CHECK', res);
                    if (res.statusCode) {
                        this.modalCheck();
                    }
                });
            }
        }
        if (this.current === 3) {
            this.finValidation();
            this.documentValidation();
            this.phoneValidation();
        }
    }

    public goToPayment(): void {
        // if (localStorage.getItem('mainToken')) {
        //     let information = JSON.stringify(
        //         new PaymentImeiStatusModel(this.imeiInfo, this.responsiveImeiCheck, this.formRegistrationData, this.url));
        //     let result = btoa(unescape(encodeURIComponent((information))));
        //     localStorage.setItem('mcqs_payment_store', result);
        //
        // }

        if (String(localStorage.getItem('mcqs_payment_store'))) {
            let string = String(localStorage.getItem('mcqs_payment_store'));
            let result = JSON.parse(atob(unescape(decodeURIComponent(string))));
            window.location.href = result.url;
        }

    }

    // validation

    public checkImei() {
        if (this.imeiInfo.simCount === 1) {
            if (!this.imeiInfo.imei.first ||
                !this.checkLengthImei(this.imeiInfo.imei.first)) {
                this.error.imei.first = true;
            } else {
                this.error.imei.first = false;
            }
        }
        if (this.imeiInfo.simCount === 2) {
            if (!this.imeiInfo.imei.first ||
                !this.checkLengthImei(this.imeiInfo.imei.first)) {
                this.error.imei.first = true;
            } else {
                this.error.imei.first = false;
            }
            if (!this.imeiInfo.imei.second ||
                !this.checkLengthImei(this.imeiInfo.imei.second)) {
                this.error.imei.second = true;
            } else {
                this.error.imei.second = false;
            }
        }
    }

    public checkLengthImei(imei: string) {
        if (imei.length == 15) {
            return true;
        } else return false;
    }

    public finValidation() {
        if (this.formRegistrationData.documentTypeNumber === 1 || this.formRegistrationData.documentTypeNumber === 4) {
            if (this.formRegistrationData.fin?.length === 7) {
                this.formError.fin = false;
            } else {
                this.formError.fin = true;
            }
        } else {
            if ((this.formRegistrationData.fin?.length <= 7 && this.formRegistrationData.fin?.length >= 3)) {
                this.formError.fin = false;
            } else {
                this.formError.fin = true;
            }
        }
    }

    public phoneValidation() {
        this.store.commit('SET_IMEI_PHONE',
            this.formRegistrationData.phone.replace(/[^0-9]+/g, '').slice(3));
        if (this.formRegistrationData.phone && this.formRegistrationData.phone.length === 9) {
            this.formError.tel = false;
        } else {
            this.formError.tel = true;
        }
    }

    public documentValidation() {
        if (!this.userData.name.length) {
            if (this.formRegistrationData.documentTypeNumber === 1) {
                if (this.formRegistrationData.documentNumber.length === 8) {
                    this.formError.document = false;
                } else {
                    this.formError.document = true;
                }
            } else {
                if (this.formRegistrationData.documentNumber.length === 7) {
                    this.formError.document = false;
                } else {
                    this.formError.document = true;
                }
            }
        }

    }

    public paymentClickModal() {
        if (this.payment) {
            this.$refs.modalPayment.closeModal();
            this.$router.push({name: 'home'});
        } else {
            this.$refs.modalPayment.closeModal();
        }
    }

    public sendReceipt(info: { email: string }) {
        this.sandbox.sendReceiptToEmail({
            transactionId: this.$route.query?.order_id,
            email: info.email
        });
    }

    public closeImeiInfoModal() {
        this.$refs.modalInfo.closeModal();
    }

    public closeImeiErrorModal() {
        this.$refs.modalError.closeModal();
        this.$router.go(-1)
    }
}
