export class UrlImeiStatusModel {
    public response: number | null = null;
    public imeiInfo: number | null = null;
    public current: number = 3;

    constructor(imei: any, response: any) {
        this._setResponse(response);
        this._setImeiInfo(imei);
    }

    private _setResponse(response: any): void {
        this.response = response;
    }

    private _setImeiInfo(imei: any): void {
        this.imeiInfo = imei;
    }
}