import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {store} from '@/store';
import {IServices} from '@/pages/allservices/types/allservices';
import {AllServicesSandbox} from '@/pages/allservices/sandbox/allservices.sandbox';
import AllServicesCardComponent from '@/pages/allservices/components/allservices-card/allservices-card.component.vue';
import AllServicesHeaderComponent
    from '@/pages/allservices/components/allservices-header/allservices-header.component.vue';
import ServiceCardComponent from '@/core/shared/service-card/service-card.component.vue';

@Options({
    name: 'all-services-component',
    components: {
        AllServicesCardComponent,
        AllServicesHeaderComponent,
        ServiceCardComponent
    },

})
export default class AllServicesComponent extends Vue {
    public store = store;
    locale$: any = locale;
    locale: any = null;
    public pageNumber = 1;
    public pageSize = 12;
    private sandbox: AllServicesSandbox = container.resolve(AllServicesSandbox);

    get services(): IServices[] {
        return store.getters._getAllServices;
    }

    get totalCountServices() {
        return this.store.getters._getAllServicesTotalCount;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.allservices) {
                this.locale = res.allservices;
            }
        });
    }

    public mounted() {
        this.sandbox.getAllServices({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
        });
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        this.sandbox.getAllServices({
            pageNumber: info.pageNumber,
            pageSize: info.pageSize,
        });
        // window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

}
