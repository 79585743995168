import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';
import {IReceiptPayment} from '@/pages/services/types/receipt-mcqs';

@injectable()
export class ServicesSandbox {
    private store = store;

    getCheckImei(payload: any): any {
        return this.store.dispatch('getCheckImei', payload);
    }

    getReceiptPayment(payload: any): any {
        return this.store.dispatch('getReceiptPayment', payload);
    }

    sendReceiptToEmail(payload: any): any {
        return this.store.dispatch('sendReceiptToEmail', payload);
    }

    postAddApplication(payload: any): any {
        return this.store.dispatch('postAddApplication', payload);
    }

    generatePaymentUrl(payload: any): any {
        return this.store.dispatch('generatePaymentUrl', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

    getDocumentGroups(): any {
        return this.store.dispatch('getDocumentGroups');
    }

    getDocumentTypes(payload:any): any {
        return this.store.dispatch('getDocumentTypes', payload);
    }


}