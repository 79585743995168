export class PostImeiStatusModel {
    public simCount: number | null = null;
    public imeiNumbers: string[] = [];

    constructor(item: any) {
        this._setSimCount(item);
        this._setImeiNumbers(item);
    }

    /**
     * Set simCount
     * @param simCount
     * @private
     */

    private _setSimCount({simCount}: any): void {
        this.simCount = simCount;
    }

    /**
     * Set imeiNumbers
     * @param imei
     * @private
     */

    private _setImeiNumbers({imei}: any): void {
        if (imei.first) {
            this.imeiNumbers.push(imei.first);
        }
        if (imei.second) {
            this.imeiNumbers.push(imei.second);
        }
    }
}