import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class CategoriesSandbox {
  private store = store;

  constructor() {
  }

  getCategories(id: string): void {
    this.store.dispatch('getCategories',id);
  }

}
