<style src="./allservices-card.component.scss" lang="scss"></style>

<template>
  <div class="all-services-card ">
<!--    <router-link :to="{name:defineService(services)}" @click="clickLink(services)">-->
      <box-component
          @click="clickLink(service)"
          :icon="service.icon"
          :title="service.title"
          :registration="service.authRequired"
          direction="column"
          bold-desc
          desc-size="16"
      >
        {{ service.description }}
      </box-component>
<!--    </router-link>-->
  </div>
</template>

<script src="./allservices-card.component.ts"></script>
