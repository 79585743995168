import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import BoxComponent from '@/core/shared/box/box.component.vue';
import choiceRouter from '@/core/helpers/routers';
import {IUser} from '@/core/auth/store/state';
import {store} from '@/store';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {container} from 'tsyringe';
import {loginStateMutations} from '@/pages/login/store/store/mutations';

@Options({
    name: 'all-services-card-component',
    components: {BoxComponent},
    props: {
        service: {
            type: Object,
            required: true,
        }, // title, webSite, tel, address, description, map
    },
})

export default class AllServicesCardComponent extends Vue {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public locale$: any = locale;
    public locale: any = null;
    private store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.allServices) {
                this.locale = res.allServices.allservicesCard;
            }
        });
    }

    get userData(): IUser {
        return this.store.getters._getUserFullData;
    }

    public defineService(service: any) {
        if (service.authRequired && !localStorage.getItem('mainToken')) {
            this.sandboxAuth.getSSOToken();
        } else {
            return choiceRouter(service.name);
        }

    }

    public clickLink(service: any) {
        if (service.authRequired && !localStorage.getItem('mainToken')) {
            this.sandboxAuth.getSSOToken(service.component);
        } else {
            this.$router.push(service.component);
        }
    }
}
