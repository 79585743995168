import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class UserApplySandbox {
    private store = store;

    constructor() {
    }

    getServiceRequests(payload: any): void {
        this.store.dispatch('getServiceRequests', payload);
    }

}
