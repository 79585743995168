import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {Validation} from '@/core/mixin/validation';

@Options({
    name: 'receipt-form-component',
    emits:['sendReceipt']
})

export default class ReceiptFormComponent extends mixins(Validation) {
    public email: string = '';
    public errorEmail: boolean = false;

    public sendReceipt() {
        if (this.validationEmail(this.email)) {
            this.errorEmail = false;
            this.$emit('sendReceipt', {
                email: this.email
            });
        } else {
            this.errorEmail = true;
        }
    }
}